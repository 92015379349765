import React, { memo } from 'react';
import { LayoutDefault } from '@app/layouts';
import './styles.scss';
import { SectionHeader } from './SectionHeader';
import { SectionAbout } from './SectionAbout';
import { SectionEssence } from './SectionEssence';
import { SectionFunctional } from './SectionFunctional';
import { SectionContacts } from './SectionContacts';
import { SectionRegistered } from './SectionRegistered';
import { SectionFAQ } from './SectionFAQ';

function AboutUs() {
  return (
    <LayoutDefault className="smr-about-us">
      <SectionHeader />
      <SectionAbout />
      <SectionEssence />
      <SectionFunctional />
      <SectionRegistered />
      <SectionFAQ />
      <SectionContacts />
    </LayoutDefault>
  );
}

export default memo(AboutUs);
