import instance from './instance';
import { ChangeUserPassword, Company, User } from '../models';
import { PageableResponse, SignInResponse } from './responses';
import { PageableParams } from './params';

export interface SignInData extends Pick<User, 'phone'> {
  password: string;
}

export interface SignUpData {
  user: SignInData & Partial<User>;
  company?: Partial<Company> | null;
}

export function signIn(data: SignInData): Promise<SignInResponse> {
  return instance(false)
    .post('/auth/signIn', data)
    .then((response) => response.data);
}

export function signUp(data: SignUpData): Promise<SignInResponse> {
  return instance(false)
    .post('/auth/signUp', data)
    .then((response) => response.data);
}

export function inviteSignUp(data: SignUpData): Promise<SignInResponse> {
  return instance(false)
    .post('/auth/inviteSignUp', { ...data, companyId: data.company?.id })
    .then((response) => response.data);
}

export function emailVerify(data: {
  email: string;
  code: string;
}): Promise<{ message: string }> {
  return instance(false)
    .post('/auth/email-verify', data)
    .then((response) => response.data);
}

export function getUserById(id: string): Promise<User> {
  return instance()
    .get(`/users/getById/${id}`)
    .then((response) => response.data);
}

export function getUserPortfoliosFilesIds(id: string): Promise<string[]> {
  return instance()
    .get(`/users/${id}/getPortfolioFilesIds`)
    .then((response) => response.data);
}

export function updateUser(id: string, data: Partial<User>): Promise<User> {
  return instance()
    .patch(`/users/${id}`, data)
    .then((response) => response.data);
}

export function getUsers(
  params: PageableParams & Partial<User>
): Promise<PageableResponse<User>> {
  return instance()
    .get('/users/getAll', { params })
    .then((response) => response.data);
}

export function checkUserExists(
  params: Pick<User, 'email' | 'phone'>
): Promise<{
  email: string | null;
  phone: string | null;
  exists: boolean;
}> {
  return instance()
    .get('/auth/checkUserExists', {
      params,
    })
    .then((response) => response.data);
}

export function checkEmailExistence(
  email: string
): Promise<{ emailExistance: boolean }> {
  return instance()
    .get('/auth/checkEmailExistence', { params: { email } })
    .then((response) => response.data);
}

export function uploadUserFiles(id: string, files: File[]): Promise<User> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/users/${id}/addFiles`, formData)
    .then((response) => response.data);
}

export function uploadUserAvatarFiles(
  id: string,
  files: File[]
): Promise<User> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/users/${id}/addAvatarFiles`, formData)
    .then((response) => response.data);
}

export function uploadUserPortfolioFiles(
  id: string,
  files: File[]
): Promise<string[]> {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file as Blob);
  });

  return instance()
    .post(`/users/${id}/addPortfolioFiles`, formData)
    .then((response) => response.data);
}

export function getSpecializations(): Promise<Array<User['specialization']>> {
  return instance()
    .get('/users/getAllSpecializations')
    .then((response) => response.data);
}

export function changePassword(data: ChangeUserPassword): Promise<any> {
  return instance()
    .patch(`/auth/password`, data)
    .then((response) => response.data);
}
export function getAuthCode(phone: string): Promise<{ message: string }> {
  return instance()
    .post(`/auth/get-auth-code`, { phone })
    .then((response) => response.data);
}

export function deleteUserFile(id: string, fileId: string): Promise<boolean> {
  return instance()
    .delete(`/users/${id}/files/${fileId}`)
    .then((response) => response.data);
}

export function deleteUserPortfolioFile(
  id: string,
  fileId: string
): Promise<boolean> {
  return instance()
    .delete(`/users/${id}/portfolioFiles/${fileId}`)
    .then((response) => response.data);
}

export function getUserByIin(iin: string): Promise<User> {
  return instance()
    .get(`/users/getUserByIin/${iin}`)
    .then((response) => response.data);
}
