import React from 'react';
import styled from 'styled-components';

const StyledAboutBadge = styled.div`
  span {
    display: inline-block;
    padding: 4px 16px;
    background: #597ef7;
    border-radius: 20px;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
  }
`;

export function AboutBadge(props: { text: string }) {
  const { text } = props;

  return (
    <StyledAboutBadge>
      <span>{text}</span>
    </StyledAboutBadge>
  );
}
