import React from 'react';
import styled from 'styled-components';
import { AboutBadge } from '../AboutBadge';

const StyledSection = styled.section`
  background: #fff;
  padding: 60px 0;
  border-radius: 48px;
  margin: -24px 16px 0;
  position: relative;
  z-index: 1;

  h4 {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    margin: 32px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: rgba(0, 0, 0, 0.65);
      font-size: 28px;
      line-height: 36px;
      display: flex;

      &:before {
        content: '';
        width: 32px;
        height: 32px;
        display: inline-flex;
        margin-top: 2px;
        margin-right: 16px;
        background: url('./about-list-point.png') center;
        background-size: cover;
      }

      + li {
        margin-top: 20px;
      }
    }
  }
`;

const StyledVideoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 48px;
  gap: 36px;

  > div {
    position: relative;
    height: 160px;
    border-radius: 20px;
    overflow: hidden;
  }

  iframe {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const StyledContainer = styled.div`
  padding: 0 100px;
`;

export function SectionAbout() {
  // @ts-ignore
  return (
    <StyledSection>
      <StyledContainer>
        <AboutBadge text="Что такое SMRhub?" />
        <h4>SMRhub – это удобная платформа, где:</h4>
        <ul>
          <li>
            Управление процессами становится более структурированным и
            прозрачным;
          </li>
          <li>Легко создавать, редактировать и отслеживать заявки;</li>
          <li>
            Пошаговая инструкция по использованию платформы представлена в
            четырех видеороликах:
          </li>
        </ul>
        <StyledVideoContainer>
          {/*@ts-ignore*/}
          {[...Array(4).keys()].map((item, itemIndex) => (
            <div key={itemIndex.toString()}>
              <iframe
                src="https://www.youtube.com/embed/W5AEFEkOhpA"
                title="THIS or a Land Cruiser? 2024 Lexus GX550 Review"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </StyledVideoContainer>
      </StyledContainer>
    </StyledSection>
  );
}
