import React from 'react';
import styled from 'styled-components';
import { AboutBadge } from '../AboutBadge';

const StyledSection = styled.section`
  background: #fff;
  padding: 60px 0;
  border-radius: 48px;
  margin: 16px;
  position: relative;
  z-index: 1;

  p {
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    margin: 32px 0 0;
  }
`;

const StyledContainer = styled.div`
  padding: 0 100px;
`;

const StyledBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-top: 32px;

  > div {
    display: flex;
    border: 1px solid #85a5ff;
    border-radius: 20px;
    padding: 40px 100px;
    gap: 24px;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      a {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #2f54eb;
      }

      &:first-child {
        flex-shrink: 0;
        flex-grow: 0;
      }

      &:last-child {
        flex-shrink: 1;
        flex-grow: 1;
      }
    }
  }
`;

export function SectionContacts() {
  // @ts-ignore
  return (
    <StyledSection>
      <StyledContainer>
        <AboutBadge text="Остались вопросы?" />
        <p>
          Свяжитесь с нами — мы оперативно ответим на все ваши вопросы и поможем
          разобраться!
        </p>
        <StyledBoxes>
          <div>
            <div>
              <svg
                width="58"
                height="58"
                viewBox="0 0 58 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.66658 9.66797H48.3332C51.0027 9.66797 53.1666 11.8319 53.1666 14.5013V43.5013C53.1666 46.1708 51.0027 48.3346 48.3332 48.3346H9.66658C6.99721 48.3346 4.83325 46.1708 4.83325 43.5013V14.5013C4.83325 11.8319 6.99721 9.66797 9.66658 9.66797ZM32.9874 37.3388L48.3332 26.5846V21.5096L30.5707 33.9555C29.6261 34.6111 28.3738 34.6111 27.4291 33.9555L9.66658 21.5096V26.5846L25.0124 37.3388C27.4073 39.0128 30.5925 39.0128 32.9874 37.3388Z"
                  fill="#2F54EB"
                />
              </svg>
            </div>
            <div>
              <p>Почта</p>
              <a href="mailto:info@smrhub.kz">Info@smrhub.kz</a>
            </div>
          </div>
          <div>
            <div>
              <svg
                width="58"
                height="58"
                viewBox="0 0 58 58"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.0833 50.7503C37.0869 50.7467 33.1526 49.759 29.6283 47.8745L28.5408 47.2703C21.0268 43.2304 14.8666 37.0701 10.8267 29.5562L10.2225 28.4687C8.28873 24.9244 7.26723 20.9545 7.25 16.9169V15.2978C7.24899 14.0096 7.76224 12.7743 8.67583 11.8661L12.76 7.78195C13.1567 7.38223 13.7165 7.18957 14.2752 7.26058C14.8338 7.33158 15.3277 7.65821 15.6117 8.14445L21.0492 17.4728C21.599 18.4223 21.4403 19.623 20.6625 20.3969L16.095 24.9645C15.7157 25.3396 15.6273 25.9191 15.8775 26.3903L16.7233 27.9853C19.7612 33.6123 24.3825 38.2252 30.015 41.2528L31.61 42.1228C32.0813 42.373 32.6608 42.2845 33.0358 41.9053L37.6033 37.3378C38.3772 36.5601 39.578 36.4014 40.5275 36.9512L49.8558 42.3887C50.3421 42.6726 50.6688 43.1666 50.7396 43.7251C50.8107 44.2838 50.6181 44.8438 50.2183 45.2403L46.1342 49.3245C45.226 50.238 43.9906 50.7513 42.7025 50.7503H41.0833Z"
                  fill="#2F54EB"
                />
              </svg>
            </div>
            <div>
              <p>Телефон</p>
              <a href="tel:+7 (777) 111 13 36">+7 (777) 111 13 36</a>
            </div>
          </div>
        </StyledBoxes>
      </StyledContainer>
    </StyledSection>
  );
}
