import { User } from './User';
import { Company } from './Company';

export interface Notification {
  id: string;
  message: string;
  data: string;
  userId: string;
  companyId: string;
  isRead: boolean;
  type: NotificationType;
  createdAt: string;
  updatedAt: string;
  company: Company;
  user: User;
}

export enum NotificationType {
  TENDER_CREATE = 10,
  TENDER_DELETE = 11,
  TENDER_STARTED = 12,
  TENDER_CLOSED = 13,

  ACCEPT_DECLINE_REPLY = 20,
  ACCEPT_DECLINE_REPLY_FOR_AUTHOR = 21,
  ACCEPT_REPLY = 22,
  ACCEPT_REPLY_FOR_AUTHOR = 23,
  DECLINE_REPLY = 24,
  DECLINE_REPLY_FOR_AUTHOR = 25,
  NEW_REPLY = 26,
  NEW_REPLY_FOR_AUTHOR_REPLY = 27,

  NEW_CONTRACT_IN_SIGN = 30,
  NEW_CONTRACT_IN_SIGN_FOR_AUTHOR = 31,
  CONTRACT_SIGNED_BOTH = 32,
  CONTRACT_IN_SIGN = 33,
  CONTRACT_END_NEED_WRITE_REVIEW = 34,
}

export const NotificationTypeReply = [
  NotificationType.ACCEPT_REPLY,
  NotificationType.ACCEPT_REPLY_FOR_AUTHOR,
  NotificationType.DECLINE_REPLY,
  NotificationType.DECLINE_REPLY_FOR_AUTHOR,
  NotificationType.NEW_REPLY,
  NotificationType.NEW_REPLY_FOR_AUTHOR_REPLY,
];

export const NotificationTypeDoc = [
  NotificationType.NEW_CONTRACT_IN_SIGN,
  NotificationType.NEW_CONTRACT_IN_SIGN_FOR_AUTHOR,
  NotificationType.CONTRACT_IN_SIGN,
];

export const NotificationTypeText = {
  [NotificationType.TENDER_CREATE]: 'Новое объявления',
  [NotificationType.TENDER_DELETE]: 'Объявление удалено',
  [NotificationType.TENDER_STARTED]: 'Объявление началось',
  [NotificationType.TENDER_CLOSED]: 'Срок объявление закончился',
  [NotificationType.ACCEPT_REPLY]: 'Отклик принят',
  [NotificationType.ACCEPT_REPLY_FOR_AUTHOR]: 'Отклик принят',
  [NotificationType.DECLINE_REPLY]: 'Отклик отклонен',
  [NotificationType.DECLINE_REPLY_FOR_AUTHOR]: 'Отклик отклонен',
  [NotificationType.NEW_REPLY]: 'Новый отклик',
  [NotificationType.NEW_REPLY_FOR_AUTHOR_REPLY]: 'Новый отклик',
  [NotificationType.ACCEPT_DECLINE_REPLY]: '',
  [NotificationType.ACCEPT_DECLINE_REPLY_FOR_AUTHOR]: '',
  [NotificationType.NEW_CONTRACT_IN_SIGN]: 'Новый договор на подписание',
  [NotificationType.NEW_CONTRACT_IN_SIGN_FOR_AUTHOR]:
    'Новый договор на подписание',
  [NotificationType.CONTRACT_SIGNED_BOTH]: 'Договор подписан',
  [NotificationType.CONTRACT_IN_SIGN]: 'Договор подписан',
  [NotificationType.CONTRACT_END_NEED_WRITE_REVIEW]:
    'Договор истек, нужно оставить отзыв',
};
