import React from 'react';
import styled from 'styled-components';
import { AboutBadge } from '../AboutBadge';

const StyledRoot = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 0 16px;
`;

const StyledSection = styled.section`
  width: 100%;
  background: #fff;
  padding: 80px 100px;
  border-radius: 48px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  h4 {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    margin: 32px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: rgba(0, 0, 0, 0.65);
      font-size: 28px;
      line-height: 36px;
      display: flex;

      &:before {
        content: '';
        width: 32px;
        height: 32px;
        display: inline-flex;
        margin-top: 2px;
        margin-right: 16px;
        background: url('./functional-list-point.png') center;
        background-size: cover;
        flex-shrink: 0;
      }

      + li {
        margin-top: 20px;
      }
    }
  }
`;

export function SectionFunctional() {
  // @ts-ignore
  return (
    <StyledRoot>
      <StyledSection>
        <AboutBadge text="Функционал" />
        <h4>Найти</h4>
        <ul>
          <li>
            Субподрядные организаций для выполнения строительно-монтажных работ
          </li>
          <li>Строительно-монтажные объемы работ</li>
          <li>Строительные компании</li>
          <li>Производителей строительных материалов</li>
        </ul>
      </StyledSection>
      <StyledSection>
        <AboutBadge text="Функционал" />
        <h4>Объявить</h4>
        <ul>
          <li>Потребность в аренде или покупке специализированной техники</li>
          <li>Аутсорсинговые кадры из сферы строительной индустрии</li>
          <li>
            Продажу или закупку строительных материалов для текущих проектов
          </li>
        </ul>
      </StyledSection>
    </StyledRoot>
  );
}
