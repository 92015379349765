import styles from './Notification.module.scss';
import Pagination from 'pages/Profile/components/Pagination';
import NotificationItemContent from './components/NotificationItemContent';
import { Button, Empty, Loader } from '@app/components';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getNotificationsForUser,
  getReplies,
  PageableParams,
  readAllNotificationsForUser,
  getDocs,
} from '@app/api';
import {
  Notification,
  NotificationTypeReply,
  NotificationTypeDoc,
  Reply,
  Doc,
} from '@app/models';
import styled from 'styled-components';
import { ModalReplyDetail, ModalContract } from '@app/modals';
import { useNotificationFromDBContext } from '@app/providers';
import { Toolbar } from '@app/common';
import { useNavigate } from 'react-router-dom';

const StyledLoader = styled.div`
  text-align: center;
  margin: 16px 0;
`;

const NotificationPage = () => {
  const { setNotifications: setNotificationsContext } =
    useNotificationFromDBContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [replyModalVisible, setReplyModalVisible] = useState<boolean>(false);
  const [targetReply, setTargetReply] = useState<string | null>(null);
  const [reply, setReply] = useState<Reply | null>(null);
  const navigate = useNavigate();
  const [readAll, setReadAll] = useState(false);
  const [targetDoc, setTargetDoc] = useState<string | null>(null);
  const [doc, setDoc] = useState<Doc | null>(null);
  const [docModalVisible, setDocModalVisible] = useState<boolean>(false);

  const onCloseDocModal = useCallback(() => {
    setDocModalVisible(false);
  }, []);

  const onCloseReplyModal = useCallback(() => {
    setReplyModalVisible(false);
  }, []);

  const onClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onClickReply = useCallback(
    (id: string) => {
      setTargetReply(id);
      setReplyModalVisible(true);
    },
    [setTargetReply, setReplyModalVisible]
  );

  const onClickDoc = useCallback(
    (id: string) => {
      setTargetDoc(id);
      setDocModalVisible(true);
    },
    [setTargetDoc, setDocModalVisible]
  );

  const onClickNotification = useCallback(
    (notification: Notification) => () => {
      if (NotificationTypeReply.includes(notification.type)) {
        onClickReply(JSON.parse(notification.data)?.replyId);
      } else if (NotificationTypeDoc.includes(notification.type)) {
        onClickDoc(JSON.parse(notification.data)?.docId);
      }
    },
    [onClickReply, onClickDoc]
  );

  const getDocsData = useCallback(async () => {
    if (!targetDoc) return;

    const defaultParams: Partial<Doc> = {};
    defaultParams.id = targetDoc;
    const response = await getDocs({
      ...({} as PageableParams),
      ...defaultParams,
    });
    setDoc(response.data[0]);
  }, [targetDoc]);

  const getNotification = useCallback(async () => {
    try {
      setIsPending(true);
      const response = await getNotificationsForUser();
      setNotifications(response);
      setNotificationsContext(response.filter((elem) => !elem.isRead));
    } catch (e) {
    } finally {
      setIsPending(false);
    }
  }, [setNotificationsContext, setNotifications]);

  const getRepliesData = useCallback(async () => {
    if (!targetReply) return;

    const defaultParams: Partial<Reply> = {};
    defaultParams.id = targetReply;
    const response = await getReplies({
      ...({} as PageableParams),
      ...defaultParams,
    });
    setReply(response.data[0]);
  }, [targetReply]);

  const onReadAllNotification = useCallback(async () => {
    await readAllNotificationsForUser(!readAll ? !readAll : undefined);
    setReadAll((prevState) => !prevState);
    await getNotification();
  }, [readAll, getNotification]);

  const rightControls = useMemo<ReactNode[]>(() => {
    return [
      <Button
        onClick={onReadAllNotification}
        text={readAll ? 'Сделать не прочитанными' : 'Прочитать все'}
      />,
    ];
  }, [readAll, onReadAllNotification]);

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    getRepliesData();
  }, [targetReply]);

  useEffect(() => {
    getDocsData();
  }, [targetDoc]);

  return (
    <div className={styles.container}>
      <Toolbar
        {...{
          title: 'Уведомления',
          rightControls: rightControls || [],
          onClickBack,
        }}
      />
      <div className={styles.main}>
        <ul className={styles.notificationLists}>
          {isPending ? (
            <StyledLoader>
              <Loader />
            </StyledLoader>
          ) : notifications.length === 0 ? (
            <Empty title={'У вас пока нет никаких уведомлений'} />
          ) : (
            notifications?.map((notification, index) => {
              return (
                <NotificationItemContent
                  notification={notification}
                  key={index}
                  onClick={onClickNotification(notification)}
                  getData={getNotification}
                />
              );
            }) ||
            (notifications.length === 0 && (
              <Empty title={'У вас пока нет никаких уведомлений'} />
            ))
          )}
        </ul>
      </div>
      <div className={styles.footer}>
        <Pagination />
      </div>
      {targetReply && reply && (
        <ModalReplyDetail
          visible={replyModalVisible}
          onClose={onCloseReplyModal}
          reply={reply}
        />
      )}
      {targetDoc && doc && (
        <ModalContract
          visible={docModalVisible}
          contract={doc}
          onClose={onCloseDocModal}
          updateContract={onCloseDocModal}
        />
      )}
    </div>
  );
};

export default NotificationPage;
