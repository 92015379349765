import React from 'react';
import styled from 'styled-components';
import { AboutBadge } from '../AboutBadge';

const StyledSection = styled.section`
  background: #fff;
  padding: 60px 0;
  border-radius: 48px;
  margin: 16px;
  position: relative;
  z-index: 1;

  p {
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    margin: 32px 0 0;
  }
`;

const StyledContainer = styled.div`
  padding: 0 100px;
`;

export function SectionEssence() {
  // @ts-ignore
  return (
    <StyledSection>
      <StyledContainer>
        <AboutBadge text="Суть проекта SMRhub" />
        <p>
          «Создание портала для партнёрсĸих взаимоотношений в сфере строительной
          индустрии, в формате «Единого оĸна», все в пару ĸлиĸов".
        </p>
      </StyledContainer>
    </StyledSection>
  );
}
