import {
  IconArrowProfile,
  IconContact,
  IconInstagram,
  IconMap,
  IconMessage,
  IconPhone,
  IconRatingStar,
  IconTelegram,
} from '@app/icons';
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { setMask } from '@app/helpers';
import {
  User,
  UserPhone,
  UserStatus,
  UserStatusNames,
  UserType,
} from '@app/models';
import { useTranslation } from 'react-i18next';
import { updateUser } from '@app/api';
import { useUserContext } from '@app/providers';
import { VisibleOnSearch } from './VisibleOnSearch';

interface IAboutUser {
  id?: string;
  ratingUser?: number;
  views: number;
  lastDateUpdate: number;
  email: string | null;
  instagram: string | null;
  telegram: string | null;
  phones: string | null;
  status?: UserStatus | null;
  webSite?: string | null;
  whatAppNumber?: string | null;
  phone?: string | null;
  editable?: boolean;
}

const NOT_SPECIFIED = 'Не указан';

interface IUserStatus {
  key: UserStatus;
  value: string;
}

const AboutUser: React.FC<IAboutUser> = memo(
  ({
    id,
    ratingUser = 0,
    views = 0,
    lastDateUpdate = 0,
    email = '',
    instagram = '',
    telegram = '',
    phones = '',
    status,
    webSite = '',
    whatAppNumber,
    phone,
    editable = false,
  }) => {
    const statusList: IUserStatus[] = [
      {
        key: UserStatus.FREE,
        value: UserStatusNames[UserStatus.FREE],
      },
      {
        key: UserStatus.BUSY,
        value: UserStatusNames[UserStatus.BUSY],
      },
    ];
    const { user } = useUserContext();
    const { t } = useTranslation();
    const [ratingArr, setRatingArr] = useState([1, 2, 3, 4, 5]);
    const [rating, setRating] = useState(ratingUser);
    const [showLists, setShowLists] = useState(false);
    const [selected, setSelected] = useState(UserStatusNames[UserStatus.FREE]);
    const [selectedValue, setSelectedValue] = useState(false);

    useEffect(() => {
      if (status) {
        setSelected(UserStatusNames[status]);
        setSelectedValue(
          UserStatusNames[status] === UserStatusNames[UserStatus.BUSY]
        );
      }
    }, [status]);

    const onSelectStatus = useCallback(
      async (selectedUser: IUserStatus) => {
        try {
          if (!editable || !id) {
            return;
          }
          setSelected(selectedUser.value);
          if (selectedUser.value === UserStatusNames[UserStatus.BUSY]) {
            setSelectedValue(true);
          } else {
            setSelectedValue(false);
          }
          await updateUser(id, { status: selectedUser.key } as User);
          setShowLists(false);
        } catch (e) {}
      },
      [editable, id]
    );

    const listRef = useRef<HTMLDivElement | null>(null);

    const preferPhone = useMemo<string | null>(() => {
      if (!phones || phones.length === 0) {
        return null;
      }

      const phoneResult = JSON.parse(phones).find(
        (phone: UserPhone) => phone.isPrefer
      );

      return !!phoneResult
        ? setMask(phoneResult.phone, '+7 (###) ### ## ##')
        : null;
    }, [phones]);

    const userPhones = useMemo<string[]>(() => {
      if (!phones || phones.length === 0) {
        return [];
      }

      return JSON.parse(phones)
        .filter((phone: UserPhone) => !phone.isPrefer && !phone.isWhatsApp)
        .map((phone: UserPhone) => setMask(phone.phone, '+7 (###) ### ## ##'));
    }, [phones]);

    useEffect(() => {
      const handler = (e: any) => {
        if (!listRef.current?.contains(e.target)) {
          setShowLists(false);
        }
      };

      document.addEventListener('click', handler);

      return () => document.removeEventListener('click', handler);
    }, []);

    return (
      <>
        <div className="p-profile__user-about">
          <VisibleOnSearch />
          {user.visible_on_search && user.type === UserType.INDIVIDUAL && (
            <div className="p-profile__user-status">
              {!!status && (
                <div
                  ref={listRef}
                  className={`${
                    !selectedValue
                      ? 'p-profile__user-status-state'
                      : 'p-profile__user-status-selected'
                  }`}
                  onClick={() =>
                    editable && setShowLists((prevState) => !prevState)
                  }
                >
                  {selected}
                  {editable && (
                    <span
                      className={`${
                        showLists
                          ? 'p-profile__user-status-arrow--active'
                          : 'p-profile__user-status-arrow--default'
                      }`}
                    >
                      <IconArrowProfile />
                    </span>
                  )}
                </div>
              )}

              {showLists && (
                <ul>
                  {statusList.map((status, i) => {
                    return (
                      <li
                        key={status.key}
                        onClick={() => onSelectStatus(status)}
                        style={{
                          color:
                            status.key === UserStatus.BUSY ? 'red' : '#25aa51',
                        }}
                      >
                        {status.value}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}

          <div className="p-profile__rating">
            <span>Рейтинг: {rating || 0}/5</span>
            <ul>
              {ratingArr.map((star, index) => {
                index += 1;
                return (
                  <Fragment key={index}>
                    <li
                      className={
                        index <= rating ? 'p-profile__star--active' : ''
                      }
                    >
                      <IconRatingStar />
                    </li>
                  </Fragment>
                );
              })}
            </ul>
          </div>
          {/*<div className="p-profile__user-view">*/}
          {/*  <IconEye /> Просмотрели профиль: {views.toString()}*/}
          {/*</div>*/}
          <div className="p-profile__user-contact">
            <IconContact />
            <span>
              Контактные сведения обновлены менее {lastDateUpdate.toString()}{' '}
              мес.назад
            </span>
          </div>
          <div className="p-profile__user-phone">
            <IconPhone />
            {!!preferPhone ||
            !!whatAppNumber ||
            !!phone ||
            userPhones.length > 0 ? (
              <ul>
                {!!phone && (
                  <li>
                    <p>{setMask(phone, '+7 (###) ### ## ##')}</p>
                    <span>- основной номер</span>
                  </li>
                )}
                {!!preferPhone && (
                  <li>
                    <p>{preferPhone}</p>
                    <span>- предпочитаемый способ связи</span>
                  </li>
                )}
                {!!whatAppNumber && (
                  <li>
                    <p>{setMask(whatAppNumber, '+7 (###) ### ## ##')}</p>
                    <span>- писать на WhatsApp</span>
                  </li>
                )}
                {userPhones.map((phone, phoneIndex) => (
                  <li key={phoneIndex.toString()}>
                    <p>{phone}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                <li>
                  <p>{t('notSpecified')}</p>
                </li>
              </ul>
            )}
          </div>
          <div className="p-profile__user-links">
            <p className="p-profile__user-email">
              <IconMessage /> {email || t('notSpecified')}
            </p>
            <p className="p-profile__user-instagram">
              <IconInstagram /> {instagram || t('notSpecified')}
            </p>
            <p className="p-profile__user-telegram">
              <IconTelegram />
              {telegram || t('notSpecified')}
            </p>
            {webSite && (
              <p className="p-profile__user-website">
                <IconMap />
                {webSite || t('notSpecified')}
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
);
export default AboutUser;
