import React from 'react';
import styled from 'styled-components';
import { AboutBadge } from '../AboutBadge';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const StyledSection = styled.section`
  background: #fff;
  padding: 60px 0;
  border-radius: 48px;
  margin: 16px;
  position: relative;
  z-index: 1;
`;

const StyledContainer = styled.div`
  padding: 0 100px;
`;

const Card = styled.div`
  padding: 16px;
  height: 289px;
  border-radius: 20px;
  background-color: #f0f5ff;
  text-align: center;
`;

const CardImage = styled.img`
  height: 70%;
  object-fit: cover;
  border-radius: 8px;
`;

const CardTitle = styled.h3`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`;

const categories = [
  { title: 'Генподрядчики', image: '/img.png' },
  { title: 'Субподрядчики', image: '/img_1.png' },
  { title: 'Производители стройматериалов', image: 'img_3.png' },
  { title: 'Владельцы спецтехники', image: 'img_4.png' },
  {
    title: 'Аутсорс кадры из строительной индустрии',
    image: 'img_6.png',
  },
];

export function SectionRegistered() {
  // @ts-ignore
  return (
    <StyledSection>
      <StyledContainer>
        <AboutBadge text="На площадĸе зарегистрированы" />
        <Swiper slidesPerView={4.5} spaceBetween={20} style={{ marginTop: 32 }}>
          {categories.map((category, index) => (
            <SwiperSlide key={index}>
              <Card>
                <CardTitle>{category.title}</CardTitle>
                <CardImage src={category.image} alt={category.title} />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </StyledContainer>
    </StyledSection>
  );
}
