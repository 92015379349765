import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

import { TenderCategory, TenderCategoryNames, UserType } from '@app/models';
import { ExpandableForm } from '@app/common';
import { useUserContext } from '@app/providers';

import Panel1Img from 'common/WelcomeBanner/1.png';
import Panel2Img from 'common/WelcomeBanner/2.png';
import Panel3Img from 'common/WelcomeBanner/3.png';
import Panel4Img from 'common/WelcomeBanner/4.png';

interface Props {
  defaultExpanded: boolean;
  value: TenderCategory | null;
  onSubmit: (tenderCategory: TenderCategory, isSubmit: boolean) => void;
}

const StyledContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  @media (max-width: 768px) {
    align-items: initial;
    display: flex;
    flex-direction: column;
  }
`;

const StyledCheckBox = styled.div`
  padding: 8px;
  width: 192px;
  background-color: #f5f5f5;
  border-radius: 10.6327px;

  p {
    margin: 0 0 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
  img {
    height: 90px;
  }
  &:hover {
    cursor: pointer;
    border: 2px solid #3a57e8;
    padding: 6px;
  }
  &.checked {
    border: 2px solid #3a57e8;
    padding: 6px;
  }
  &.disable {
    display: none;
  }
`;

function TenderCategoryForm(props: Props) {
  const { user } = useUserContext();
  const { defaultExpanded, value, onSubmit } = props;
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [checkedItem, setCheckedItem] = useState(0);

  const onChange = (tenderCategory: TenderCategory) => () => {
    setCheckedItem(tenderCategory);
    onSubmit(tenderCategory, false);
  };

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setExpanded(false);
      onSubmit(value as TenderCategory, true);
    },
    [onSubmit, value]
  );

  return (
    <ExpandableForm
      submitLabel="Далее"
      title="Выберите категорию объявления"
      onSubmit={onSubmitForm}
      description={value ? TenderCategoryNames[value] : ''}
      expanded={defaultExpanded}
      onChangeSwitch={onChangeSwitch}
      disabled={!value || !defaultExpanded}
      disableSwitcher={true}
    >
      <StyledContainer>
        <StyledCheckBox
          className={`
            ${checkedItem === TenderCategory.CONTRACTOR ? 'checked' : ''} 
            ${user.type === UserType.INDIVIDUAL ? 'disable' : ''}
          `}
          onClick={onChange(TenderCategory.CONTRACTOR)}
        >
          <p>{TenderCategoryNames[TenderCategory.CONTRACTOR]}</p>
          <div>
            <img
              src={Panel1Img}
              alt={TenderCategoryNames[TenderCategory.CONTRACTOR]}
            />
          </div>
        </StyledCheckBox>
        <StyledCheckBox
          className={`
            ${checkedItem === TenderCategory.SUPPLIER ? 'checked' : ''} 
            ${user.type === UserType.INDIVIDUAL ? 'disable' : ''}
          `}
          onClick={onChange(TenderCategory.SUPPLIER)}
        >
          <p>{TenderCategoryNames[TenderCategory.SUPPLIER]}</p>
          <div>
            <img
              src={Panel2Img}
              alt={TenderCategoryNames[TenderCategory.SUPPLIER]}
            />
          </div>
        </StyledCheckBox>
        <StyledCheckBox
          className={`${
            checkedItem === TenderCategory.VEHICLE ? 'checked' : ''
          }`}
          onClick={onChange(TenderCategory.VEHICLE)}
        >
          <p>{TenderCategoryNames[TenderCategory.VEHICLE]}</p>
          <div>
            <img
              src={Panel3Img}
              alt={TenderCategoryNames[TenderCategory.VEHICLE]}
            />
          </div>
        </StyledCheckBox>
        <StyledCheckBox
          onClick={onChange(TenderCategory.WORKER)}
          className={`${
            checkedItem === TenderCategory.WORKER ? 'checked' : ''
          }`}
        >
          <p>{TenderCategoryNames[TenderCategory.WORKER]}</p>
          <div>
            <img
              src={Panel4Img}
              alt={TenderCategoryNames[TenderCategory.WORKER]}
            />
          </div>
        </StyledCheckBox>
      </StyledContainer>
    </ExpandableForm>
  );
}

export default memo(TenderCategoryForm);
