import React, { memo } from 'react';
import classNames from 'classnames';
import { IconCheck, IconCloseArrow } from '@app/icons';
import Container from '../Container';
import './styles.scss';

interface Props {
  title?: string;
  currentStep: number;
  maxCurrentStep: number;
  steps: { onClick: () => void; title: string }[];
  onClose?: () => void;
  fullWidth?: boolean;
}

function Stepper(props: Props) {
  const {
    title,
    steps,
    currentStep,
    maxCurrentStep,
    onClose,
    fullWidth = false,
  } = props;

  return (
    <div className="b-stepper">
      <div className="b-stepper__header">
        <Container fullWidth={fullWidth}>
          {!!title && (
            <span className="b-stepper__title">
              {title}{' '}
              <div onClick={onClose}>
                <IconCloseArrow />
              </div>
            </span>
          )}
          <div className="b-stepper__steps">
            {steps.map((step, stepIndex) => (
              <div
                className={classNames('b-stepper__item', {
                  'b-stepper__item--active': stepIndex === currentStep,
                  'b-stepper__item--finished': stepIndex < currentStep,
                })}
                key={stepIndex.toString()}
              >
                <p className="b-stepper__number">
                  {stepIndex < currentStep ? (
                    <IconCheck />
                  ) : (
                    (stepIndex + 1).toString()
                  )}
                </p>
                <p
                  // onClick={currentStep <= maxCurrentStep ? step.onClick : undefined}
                  className="b-stepper__label"
                >
                  {step.title}
                </p>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default memo(Stepper);
