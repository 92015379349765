import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  KatoSelectTrigger,
  SelectOption,
  TextField,
} from '@app/components';
import { Kato, UserType } from '@app/models';
import * as yup from 'yup';
import { ExpandableForm } from '@app/common';
import { DN } from '../../../../libs';
import {
  checkUserExists,
  inviteSignUp,
  signUp,
  checkEmailExistence,
} from '@app/api';
import Cookies from 'js-cookie';
import { parseFullName } from '@app/helpers';
import styled from 'styled-components';
import { useForm } from '@app/forms';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface UserFormData {
  fullName: string;
  email: string;
  phone: string;
  kato: string;
  password: string;
  passwordConfirm: string;
  avatar: File | null;
  companyId: string | null;
}

export type InviteDN = DN & { companyId?: string | null; kato?: Kato };

interface UserFormProps {
  userType: UserType;
  dn: InviteDN | null;
  onSubmit: (data: UserFormData) => void;
  defaultExpanded: boolean;
  isInvite?: boolean;
}

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 32px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`;

function UserForm(props: UserFormProps) {
  const navigate = useNavigate();
  const { dn, defaultExpanded, onSubmit, userType, isInvite } = props;
  const { t } = useTranslation();
  const { values, onChange, errors, validate, setErrors } =
    useForm<UserFormData>({
      fullName: !!dn
        ? `${dn.lastName} ${dn.firstName} ${dn.middleName}`.trim()
        : '',
      email: !!dn ? dn.email : '',
      phone: '',
      kato: '',
      password: '',
      passwordConfirm: '',
      avatar: null,
      companyId: dn?.companyId ?? null,
    });
  const [pending, setPending] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [kato, setKato] = useState<Kato | null>(null);

  const onChangeKato = useCallback(
    (option: SelectOption<Kato>) => {
      setKato(option.value);
      onChange(option.value.nameRu, 'kato');
    },
    [onChange]
  );

  const onChangeAvatar = useCallback(
    (file: File | null) => {
      onChange(file, 'avatar');
    },
    [onChange]
  );

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();

        const schema = yup.object().shape({
          fullName: yup.string().required(t('required', { ns: 'validation' })!),
          phone: yup.string().required(t('required', { ns: 'validation' })!),
          email: yup
            .string()
            .email()
            .required(t('required', { ns: 'validation' })!),
          kato: yup.string().required(t('required', { ns: 'validation' })!),
          password: yup
            .string()
            .required(t('required', { ns: 'validation' })!)
            .min(8, 'Пароль должен содержать 8 или более символов')
            .test(
              'complexity',
              'Пароль должен содержать как минимум 1 строчную букву, 1 заглавную букву, 1 цифру и 1 специальный символ.',
              (value) => {
                if (!value) return false;

                const hasLowercase = /[a-z]/.test(value);
                const hasUppercase = /[A-Z]/.test(value);
                const hasNumber = /\d/.test(value);
                const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);

                return hasLowercase && hasUppercase && hasNumber && hasSymbol;
              }
            ),
          passwordConfirm: yup
            .string()
            .oneOf(
              [yup.ref('password')],
              t('passwordConfirmation', { ns: 'validation' })!
            ),
        });

        const hasErrors = await validate(schema);

        if (hasErrors) {
          return;
        }

        const { email, phone } = await checkUserExists({
          email: values.email,
          phone: values.phone,
        });

        const { emailExistance } = await checkEmailExistence(values.email);

        if (email || phone) {
          setErrors({
            email: email
              ? 'Пользователь с данным email уже зарегистрирован в системе'
              : undefined,
            phone: phone
              ? 'Пользователь с данным телефоном уже зарегистрирован в системе'
              : undefined,
          });

          return;
        }

        if (!emailExistance) {
          setErrors({
            email: !emailExistance ? 'Такой email не существует' : undefined,
          });
          return;
        }

        if (userType === UserType.ENTITY && !isInvite) {
          onSubmit({
            ...values,
            kato: kato!.code,
          });

          setExpanded(false);

          return;
        }

        setPending(true);

        const userNameData = parseFullName(values.fullName);

        const body = {
          user: {
            firstName: userNameData.firstName,
            lastName: userNameData.lastName,
            middleName: userNameData.middleName,
            email: values.email,
            phone: values.phone,
            password: values.password,
            type: userType,
            katoCode: kato!.code,
          },
        };

        const response =
          isInvite && values.companyId
            ? inviteSignUp({ ...body, company: { id: values.companyId } })
            : signUp(body);

        response
          .then((userResponse) => {
            navigate(`/email-verify?email=${encodeURIComponent(values.email)}`);
          })
          .catch(() => {
            setPending(false);
          });
      } catch (e) {}
    },
    [t, validate, values, userType, isInvite, kato, setErrors, onSubmit]
  );

  useEffect(() => {
    if (dn?.kato) {
      setKato(dn?.kato);
      onChange(dn?.kato.nameRu, 'kato');
    }
  }, [dn?.kato, onChange]);

  return (
    <ExpandableForm
      title="Личные данные"
      description={values.fullName || undefined}
      expanded={expanded}
      onChangeSwitch={onChangeSwitch}
      onSubmit={onSubmitForm}
      disabled={!expanded}
      loading={pending}
      submitLabel={userType === UserType.ENTITY ? 'Далее' : 'Создать'}
      autoComplete="off"
      disableSwitcher
    >
      <StyledContainer>
        <Avatar onChange={onChangeAvatar} value={values.avatar} size={150} />
        <StyledGrid>
          <TextField
            label="Ф.И.О"
            value={values.fullName}
            name="fullName"
            onChange={(value) => onChange(value, 'fullName')}
            placeholder="Введите Ф.И.О."
            helperText={errors.fullName}
            error={!!errors.fullName}
          />
          <TextField
            label="Номер телефона"
            onChange={(value) => onChange(value, 'phone')}
            value={values.phone}
            mask="(###) ### ## ##"
            placeholder="(777) 777 77 77"
            helperText={errors.phone}
            error={!!errors.phone}
            name="phone"
          />
          <TextField
            label="Email"
            value={values.email}
            name="email"
            onChange={(value) => onChange(value, 'email')}
            placeholder="Введите электронный адрес (почту)"
            helperText={errors.email}
            error={!!errors.email}
          />
          <KatoSelectTrigger
            onChange={onChangeKato}
            label="регион"
            value={values.kato}
            placeholder="Выберите регион"
            helperText={errors.kato}
            error={!!errors.kato}
          />
          <TextField
            label="Придумайте пароль"
            onChange={(value) => onChange(value, 'password')}
            secureTextEntry={true}
            value={values.password}
            placeholder="ХХХХХХХХХХХ"
            helperText={errors.password}
            error={!!errors.password}
            name="password"
            autoComplete="off"
          />
          <TextField
            label="Повторите пароль"
            onChange={(value) => onChange(value, 'passwordConfirm')}
            secureTextEntry={true}
            value={values.passwordConfirm}
            placeholder="ХХХХХХХХХХХ"
            helperText={errors.passwordConfirm}
            error={!!errors.passwordConfirm}
            name="passwordConfirm"
            autoComplete="off"
          />
        </StyledGrid>
      </StyledContainer>
    </ExpandableForm>
  );
}

export default memo(UserForm);
