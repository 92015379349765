import React, { useState } from 'react';
import styled from 'styled-components';
import { AboutBadge } from '../AboutBadge';

const StyledSection = styled.section`
  background: #fff;
  padding: 60px 0;
  border-radius: 48px;
  margin: 16px;
  position: relative;
  z-index: 1;
`;

const StyledContainer = styled.div`
  padding: 0 100px;
`;

const Question = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 28px 48px;
  background: ${({ isOpen }) => (isOpen ? '#FFF' : '#F0F5FF')};
  border-radius: 18px;
  margin-top: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isOpen }) => (isOpen ? '#597EF7' : '#F0F5FF')};

  button {
    margin-left: auto;
    padding: 12px;
    display: inline-flex;
    border: none;
    outline: none;
    border-radius: 8px;
    background: #3a57e8;
    color: #fff;
    cursor: pointer;
  }

  h4 {
    font-size: 24px;
    color: #1d1d1f;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    margin-top: 24px;
    font-weight: 400;
    color: #697180;
    font-size: 22px;
  }
`;

interface FAQItemProps {
  question: string;
  answer: string;
  defaultIsOpen: boolean;
}

const FAQItem: React.FC<FAQItemProps> = ({
  question,
  answer,
  defaultIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <div>
      <Question isOpen={isOpen}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <h4>{question}</h4>
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8126 4H12.6407C12.561 4 12.486 4.03906 12.4391 4.10313L8.00008 10.2219L3.56101 4.10313C3.51414 4.03906 3.43914 4 3.35945 4H2.18758C2.08601 4 2.02664 4.11563 2.08601 4.19844L7.59539 11.7937C7.79539 12.0687 8.20476 12.0687 8.4032 11.7937L13.9126 4.19844C13.9735 4.11563 13.9141 4 13.8126 4Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.964 7.60637L4.92031 2.10481C4.90191 2.09032 4.87979 2.08131 4.8565 2.07883C4.8332 2.07634 4.80968 2.08048 4.78863 2.09076C4.76758 2.10104 4.74986 2.11704 4.7375 2.13694C4.72514 2.15684 4.71864 2.17982 4.71875 2.20325V3.41106C4.71875 3.48762 4.75469 3.56106 4.81406 3.60793L10.439 8.00012L4.81406 12.3923C4.75313 12.4392 4.71875 12.5126 4.71875 12.5892V13.797C4.71875 13.9017 4.83906 13.9595 4.92031 13.8954L11.964 8.39387C12.0239 8.34717 12.0723 8.28744 12.1057 8.21921C12.139 8.15098 12.1563 8.07605 12.1563 8.00012C12.1563 7.92419 12.139 7.84927 12.1057 7.78104C12.0723 7.71281 12.0239 7.65307 11.964 7.60637Z"
                  fill="white"
                />
              </svg>
            )}
          </button>
        </div>
        {isOpen && (
          <div>
            <p>{answer}</p>
          </div>
        )}
      </Question>
    </div>
  );
};

const faqs = [
  {
    question: 'Что такое SMRHub?',
    answer: 'SMRHub - это платформа для строительных материалов и услуг.',
  },
  {
    question: 'Как зарегистрироваться в SMRHub?',
    answer: 'Вы можете зарегистрироваться, перейдя на страницу регистрации.',
  },
  {
    question: 'Как продать строительные материалы на SMRHub?',
    answer: 'Создайте учетную запись и добавьте свои товары в каталог.',
  },
];

export function SectionFAQ() {
  // @ts-ignore
  return (
    <StyledSection>
      <StyledContainer>
        <AboutBadge text="FAQ" />
        <br />
        <br />
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            defaultIsOpen={index === 0}
          />
        ))}
      </StyledContainer>
    </StyledSection>
  );
}
