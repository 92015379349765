import React, {
  forwardRef,
  Fragment,
  KeyboardEventHandler,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { setMask } from '@app/helpers';
import './styles.scss';
import styled from 'styled-components';
import { IconClear, IconCloseEye, IconEye } from '@app/icons';

const StylesTextField = styled.input`
  border: none;
  outline: none;
  width: 100%;
  border-radius: 8px;
  height: 52px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 16px;
  padding-right: 40px;
  padding-bottom: 8px;
  border: 1px solid transparent;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0a0a0a;
  &:focus {
    outline: none;
    border: 1px solid #c3dff7;
  }
`;

const Label = styled.label`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: relative;
`;

const Title = styled.p`
  color: #757575;
  position: absolute;
  left: 16px;
  top: 4px;
  padding: 0;
  margin: 0;
`;

const PasswordIcon = styled.span`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111;
  svg {
    z-index: 111111;
    cursor: pointer;
    path {
      stroke: #c3dff7;
    }
  }
`;

const ClearIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111;
  svg {
    z-index: 111111;
    cursor: pointer;
  }
`;

const ErrorTitle = styled.p`
  width: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 111111;
  color: red;
`;

const TextFieldInputContainer = styled.div<{ multiline: boolean }>`
  display: flex;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  align-items: center;

  ${(props) =>
    props.multiline &&
    `
    height: auto;
    min-height: 100px;
    align-items: flex-start;
  `}

  input,
  textarea {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 8px 16px;
    ${(props) =>
      props.multiline &&
      `
      height: auto;
      min-height: 100px;
      resize: none;
      overflow: hidden;
    `}
  }

  > div {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 8px;

    svg {
      cursor: pointer;

      + svg {
        margin-left: 8px;
      }
    }
  }
`;

function unSetMask(value: string): string {
  return value.replace(/\D/g, '');
}

export interface TextFieldProps {
  label: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string, name?: string) => void;
  helperText?: string | null;
  error?: boolean;
  mask?: string;
  secureTextEntry?: boolean;
  name?: string;
  editable?: boolean;
  onClick?: () => void;
  onClickClear?: () => void;
  endIcons?: ReactNode[];
  onSendMessage?: () => void;
  required?: boolean;
  variant?: 'default' | 'modern';
  className?: string;
  multiline?: boolean;
  autoComplete?: 'on' | 'off';
}

type Ref = any;

function TextField(props: TextFieldProps, ref: Ref) {
  const {
    label,
    value,
    onChange,
    placeholder,
    helperText = '',
    error = false,
    mask,
    secureTextEntry = false,
    name,
    editable = true,
    endIcons,
    onClick,
    onSendMessage,
    required = false,
    variant = 'default',
    className = '',
    multiline = false,
    autoComplete = 'on',
  } = props;

  const [showHiddenText, setShowHiddenText] = useState<boolean>(false);
  const inputElement = useRef<HTMLInputElement>(null);
  const textAreaElement = useRef<HTMLTextAreaElement>(null);

  const valueText = useMemo<string>(() => {
    return mask ? setMask(value || '', mask.trim()) : value || '';
  }, [mask, value]);

  const onClickClear = useCallback(() => {
    if (!!props.onClickClear) {
      props.onClickClear();
    }

    onChange?.('', name);
  }, [onChange, props, name]);

  const onKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (!!onSendMessage) {
          onSendMessage();
        }
      }
    },
    [onSendMessage]
  );

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!editable || !onChange) {
        return;
      }

      const { value: inputValue } = e.target;

      if (!mask) {
        onChange(inputValue, name as string);

        return;
      }

      if (inputValue.length <= mask.trim().length) {
        onChange(unSetMask(inputValue), name as string);
      }
    },
    [editable, mask, name, onChange]
  );

  const onClickInput = useCallback(
    (e: React.MouseEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!!onClick) {
        e.preventDefault();
        e.stopPropagation();

        if (multiline) {
          if (!!textAreaElement.current) textAreaElement.current.blur();
        } else if (!!inputElement.current) {
          inputElement.current.blur();
        }

        onClick();
      }
    },
    [multiline, onClick]
  );

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (autoComplete === 'off') e.target.removeAttribute('readonly');
    },
    [autoComplete]
  );

  const onChangeType = useCallback(() => {
    setShowHiddenText((prevShowHidden) => !prevShowHidden);
  }, []);

  if (variant === 'modern') {
    return (
      <>
        <Label className={`signin-label ${className}`}>
          <Title className={`signin-label__title ${className}`}>{label}</Title>

          <StylesTextField
            ref={ref}
            type={secureTextEntry && !showHiddenText ? 'password' : 'text'}
            className={`signin-label__input ${className}`}
            onChange={onChangeInput}
            value={valueText}
            disabled={!editable}
            placeholder={placeholder}
            onClick={onClickInput}
            onKeyDown={onKeyDown}
          />
          {secureTextEntry && (
            <>
              {showHiddenText ? (
                <PasswordIcon
                  onClick={onChangeType}
                  className={`signin-label__input-close-eye ${className}`}
                >
                  <IconCloseEye />
                </PasswordIcon>
              ) : (
                <PasswordIcon
                  onClick={onChangeType}
                  className={`signin-label__input-open-eye ${className}`}
                >
                  <IconEye />
                </PasswordIcon>
              )}
            </>
          )}
          {!secureTextEntry && !!valueText && (
            <ClearIcon
              onClick={onClickClear}
              className={`signin-label__input-clear ${className}`}
            >
              <IconClear />
            </ClearIcon>
          )}
          {endIcons &&
            endIcons.map((endIcon, endIconIndex) => (
              <Fragment key={endIconIndex.toString()}>{endIcon}</Fragment>
            ))}
        </Label>
        {!!helperText && (
          <ErrorTitle className={`signin-label__input-error ${className}`}>
            {helperText}
          </ErrorTitle>
        )}
      </>
    );
  }

  return (
    <div
      className={classNames('smr-text-field', className, {
        'smr-text-field--error': error,
        'smr-text-field--not-editable': !editable,
      })}
    >
      <label className="smr-text-field__label">
        {label}
        {required ? ' *' : ''}
      </label>
      <TextFieldInputContainer
        className="smr-text-field__input-container"
        multiline={multiline}
      >
        {multiline ? (
          <textarea
            ref={textAreaElement}
            className={classNames('smr-text-field__input', {
              'smr-text-field__input--clickable': !!onClick,
              'smr-text-field__input--multiline': multiline,
            })}
            onChange={onChangeInput}
            value={valueText}
            disabled={!editable}
            placeholder={placeholder}
            onClick={onClickInput}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            onFocus={onFocus}
            readOnly={autoComplete === 'off'}
          />
        ) : (
          <input
            ref={inputElement}
            type={secureTextEntry && !showHiddenText ? 'password' : 'text'}
            className={classNames('smr-text-field__input', {
              'smr-text-field__input--clickable': !!onClick,
            })}
            onChange={onChangeInput}
            value={valueText}
            disabled={!editable}
            placeholder={placeholder}
            onClick={onClickInput}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            onFocus={onFocus}
            readOnly={autoComplete === 'off'}
          />
        )}
        <div
          className={
            !secureTextEntry && !!valueText && !!onChange
              ? 'smr-text-field__clear'
              : undefined
          }
        >
          {secureTextEntry && (
            <>
              {showHiddenText ? (
                <IconCloseEye
                  className="smr-text-field__eye"
                  onClick={onChangeType}
                />
              ) : (
                <IconEye
                  className="smr-text-field__eye"
                  onClick={onChangeType}
                />
              )}
            </>
          )}
          {!secureTextEntry && !!valueText && !!onChange && (
            <IconClear onClick={onClickClear} />
          )}
          {endIcons &&
            endIcons.map((endIcon, endIconIndex) => (
              <Fragment key={endIconIndex.toString()}>{endIcon}</Fragment>
            ))}
        </div>
      </TextFieldInputContainer>
      {!!helperText && (
        <p className="smr-text-field__helper-text">{helperText}</p>
      )}
    </div>
  );
}

export default memo(forwardRef<Ref, TextFieldProps>(TextField));
