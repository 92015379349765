import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Form,
  OkedSelectTrigger,
  SelectOption,
  StyledOkedListBlock,
  TextField,
} from '@app/components';
import * as yup from 'yup';
import { Oked, UserType } from '@app/models';
import './styles.scss';
import { DN } from '../../../../libs';
import { UserFormData } from '../User';
import { signUp } from '@app/api';
import Cookies from 'js-cookie';
import { parseFullName } from '@app/helpers';
import { useForm } from '@app/forms';
import { useTranslation } from 'react-i18next';
import { useNotification } from '@app/providers';
import { useNavigate } from 'react-router-dom';

export interface CompanyFormData {
  okeds: Oked[];
  name: string;
}

interface CompanyFormProps {
  dn: DN;
  user: UserFormData;
}

function CompanyForm(props: CompanyFormProps) {
  const navigate = useNavigate();
  const { dn, user } = props;
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const { values, onChange, errors, validate } = useForm<CompanyFormData>({
    name: !!dn.company ? dn.company.name : '',
    okeds: [],
  });
  const [pending, setPending] = useState<boolean>(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('required', { ns: 'validation' })!),
      }),
    [t]
  );

  const onChangeOked = useCallback(
    (data: SelectOption<Oked>) => {
      onChange([data.value, ...values.okeds], 'okeds');
    },
    [onChange, values.okeds]
  );

  const onClearOked = useCallback(
    (number: number) => {
      onChange(
        values.okeds.filter((val, index) => index !== number),
        'okeds'
      );
    },
    [onChange, values.okeds]
  );

  const onSubmitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const hasErrors = await validate(schema);

      if (hasErrors) {
        return;
      }

      setPending(true);

      const userNameData = parseFullName(user.fullName);

      signUp({
        user: {
          firstName: userNameData.firstName,
          lastName: userNameData.lastName,
          middleName: userNameData.middleName,
          email: user.email,
          phone: user.phone,
          password: user.password,
          type: UserType.ENTITY,
          katoCode: user.kato,
        },
        company: {
          bin: dn!.company!.bin,
          name: values.name,
          type: dn!.company!.type,
          okeds: values.okeds,
        },
      })
        .then((userResponse) => {
          navigate(`/verify-email?email=${encodeURIComponent(user.email)}`);
        })
        .catch((e) => {
          showNotification({
            variant: 'error',
            message:
              e?.response?.data?.message ??
              'Ошибка, обратитесь к администратору или попробуйте позднее',
          });

          setPending(false);
        });
    },
    [
      dn,
      schema,
      user.email,
      user.fullName,
      user.kato,
      user.password,
      user.phone,
      validate,
      values.name,
      values.okeds,
    ]
  );

  return (
    <Form
      onSubmit={onSubmitForm}
      title="Организация"
      className="form-company-info"
      loading={pending}
      submitText="Создать"
    >
      <div className="form-company-info__grid">
        <TextField label="БИН" value={dn.company!.bin} editable={false} />
        <TextField
          label="Наименование"
          value={values.name}
          name="name"
          onChange={onChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <OkedSelectTrigger
          label="Категория"
          value={''}
          onChange={onChangeOked}
          name="oked"
          placeholder="ХХХХХХХХХХХ"
          helperText={errors.okeds}
          error={!!errors.okeds}
        />
        <StyledOkedListBlock list={values.okeds} onClickClear={onClearOked} />
      </div>
    </Form>
  );
}

export default memo(CompanyForm);
