import React from 'react';
import styled from 'styled-components';
import sectionBGImage from './bg.jpg';

const StyledSection = styled.section`
  position: relative;
  margin-top: -16px;
  padding: 32px 16px;
  box-sizing: border-box;
`;

const StyledSectionContainer = styled.div`
  min-height: 500px;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    position: relative;
    font-size: 64px;
    font-weight: 600;
    line-height: 64px;
    color: #fff;
    z-index: 2;
    border-bottom: 7px solid #2f54eb;
    display: inline;
    padding-bottom: 20px;
  }

  p {
    position: relative;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    color: #fff;
    z-index: 2;
    padding-top: 30px;
  }

  > div:last-child {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    background-image: url(${sectionBGImage});
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0 72%;
    background-size: cover;
    z-index: 1;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export function SectionHeader() {
  return (
    <StyledSection>
      <StyledSectionContainer>
        <div>
          <h1>SMRhub</h1>
        </div>
        <p>
          Мы – молодая стартап ĸоманда, имеющая опыт в строительной индустрии.
          <br />
          <br />
          Наша цель – реализовать цифровой проеĸт, ĸоторый упростит и усĸорит
          процессы в данной отрасли. Мы работаем на результат, воплощаем наши
          идеи в жизнь и стремимся ĸ постоянному развитию.
        </p>
        <div />
      </StyledSectionContainer>
    </StyledSection>
  );
}
