import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePicker,
  Grid,
  KatoSelectTrigger,
  OkedSelectTrigger,
  Paper,
  Select,
  SelectOption,
  TextField,
} from '@app/components';
import {
  Kato,
  Measure,
  MeasureLabelsFull,
  Oked,
  Tender,
  TenderCategory,
  UserType,
} from '@app/models';
import * as yup from 'yup';
import './styles.scss';
import { mapRecordToSelectOption, onlyDigit } from '@app/helpers';
import { ExpandableForm } from '@app/common';
import styled from 'styled-components';
import { IconClear, IconSendMessage } from '@app/icons';
import { useUserContext } from '@app/providers';
import { addDays } from 'date-fns';
import { useForm } from '../hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultExpanded: boolean;
  onSubmit: (data: TenderFormData) => void;
  tenderCategory: TenderCategory | null;
  tender?: Tender;
  setValues?: (values: Partial<TenderFormValues>) => void;
  edit?: boolean;
  errors?: any;
  data?: Partial<TenderFormData>;
}

export interface TenderFormValues {
  oked: Oked | null;
  kato: Kato | null;
  name: string;
  description: string;
  deliveryDate: Date | null;
  endDate: Date | null;
  price: string;
  measure: SelectOption<Measure> | null;
}

export interface TenderFormData {
  oked: string | null;
  kato: string;
  name: string;
  description: string;
  deliveryDate: Date | null;
  endDate: Date | null;
  price: string;
  companyId: string | null;
  requirements?: string[];
  measure: Measure | null;
}

const TenderFormFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

const RequirementsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-top: -16px;
`;

const RequirementStyled = styled.div`
  background: #f5f5f5;
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 16px;
  margin-left: 16px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0a0a0a;
    margin: 0 16px 0 0;
  }
`;

function TenderForm(props: Props) {
  const {
    setValues: setValuesProps,
    defaultExpanded,
    onSubmit,
    tenderCategory,
    tender,
    edit = false,
    errors: errorsProps,
    data,
  } = props;
  const { user, company } = useUserContext();
  const { t } = useTranslation();
  const [requirement, setRequirement] = useState<string>('');
  const [requirements, setRequirements] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const { values, onChange, errors, validate, setValues, setErrors } =
    useForm<TenderFormValues>({
      oked: null,
      kato: null,
      name: data?.name ?? '',
      description: '',
      endDate: null,
      deliveryDate: null,
      price: data?.price ?? '',
      measure: null,
    });

  useEffect(() => {
    if (tender) {
      setValues({
        oked: tender.oked,
        kato: tender.kato,
        name: tender.name,
        description: tender.description,
        endDate: new Date(tender.endDate),
        deliveryDate: new Date(tender.deliveryDate),
        price: tender.price,
        measure: tender.measure
          ? {
              label: MeasureLabelsFull[tender.measure],
              value: +Measure[tender.measure],
            }
          : null,
      });
      if (tender?.requirements?.length > 0) {
        setRequirements(tender.requirements.map((elem) => elem.name));
      }
    }
  }, []);

  useEffect(() => {
    if (setValuesProps) {
      setValuesProps({ ...values });
    }
  }, [values]);

  useEffect(() => {
    if (errorsProps) {
      setErrors(errorsProps);
    }
  }, [errorsProps]);

  const onChangeSwitch = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onChangeRequirement = useCallback((value: string) => {
    setRequirement(value);
  }, []);

  const onClickAddRequirement = useCallback(() => {
    setRequirements((prevRequirements) => [...prevRequirements, requirement]);
    setRequirement('');
  }, [requirement]);

  const onClickDeleteRequirement = useCallback(
    (index: number) => () => {
      setRequirements((prevRequirements) =>
        prevRequirements.filter(
          (prevRequirement, prevRequirementIndex) =>
            prevRequirementIndex !== index
        )
      );
    },
    []
  );

  const onChangeOked = useCallback(
    (data: SelectOption<Oked>) => {
      onChange(data.value, 'oked');
    },
    [onChange]
  );

  const onChangeKato = useCallback(
    (data: SelectOption<Kato>) => {
      onChange(data.value, 'kato');
    },
    [onChange]
  );

  const onChangePrice = useCallback(
    (value: string) => {
      onChange(onlyDigit(value), 'price');
    },
    [onChange]
  );

  const onClickSubmitForm = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const hasErrors = await validate(
        yup.object().shape({
          oked:
            tenderCategory === TenderCategory.WORKER
              ? yup.object().nullable()
              : yup
                  .object()
                  .nullable()
                  .required(t('required', { ns: 'validation' })!),
          kato: yup
            .object()
            .nullable()
            .required(t('required', { ns: 'validation' })!),
          name: yup.string().required(t('required', { ns: 'validation' })!),
          description: yup
            .string()
            .required(t('required', { ns: 'validation' })!),
          endDate: yup.date().required(t('required', { ns: 'validation' })!),
          deliveryDate: yup
            .date()
            .required(t('required', { ns: 'validation' })!),
          price:
            tenderCategory === TenderCategory.WORKER
              ? yup.string()
              : yup.string().required(t('required', { ns: 'validation' })!),
        })
      );

      if (hasErrors) {
        return;
      }

      onSubmit({
        ...values,
        oked: values.oked ? values.oked.code : null,
        kato: values.kato!.code,
        requirements,
        companyId: user.type === UserType.ENTITY ? company!.id : null,
        measure: values.measure && values.measure.value,
      });

      setExpanded(false);
    },
    [
      validate,
      tenderCategory,
      onSubmit,
      values,
      requirements,
      user.type,
      company,
      t,
    ]
  );

  const measureOptions = useMemo(() => {
    const measureArray = Object.values(Measure).filter((value) =>
      Number.isFinite(value)
    );
    return measureArray.map((measure) =>
      mapRecordToSelectOption(measure, MeasureLabelsFull)
    );
  }, []);

  return (
    <>
      <ExpandableForm
        title="Подать заявку"
        description={values.name || ''}
        expanded={expanded}
        onChangeSwitch={onChangeSwitch}
        disabled={true}
        viewHead={!edit}
        nullablePadding={edit}
      >
        <Grid>
          <Grid columns={2}>
            <TextField
              value={values.name}
              onChange={onChange}
              name="name"
              label="Наименование"
              placeholder="Введите наименование"
              error={!!errors.name}
              helperText={errors.name}
              required={true}
            />
            {tenderCategory !== TenderCategory.WORKER && (
              <OkedSelectTrigger
                label="Выберите категорию"
                value={values.oked ? values.oked.nameRu : ''}
                onChange={onChangeOked}
                helperText={errors.oked}
                error={!!errors.oked}
                required={true}
              />
            )}
            {tenderCategory !== TenderCategory.WORKER && (
              <>
                <TextField
                  value={values.price}
                  onChange={onChangePrice}
                  name="price"
                  label="объем"
                  placeholder="Введите объем"
                  error={!!errors.price}
                  helperText={errors.price}
                  required={true}
                />
                <Select
                  label="Единицы измерения"
                  value={values.measure}
                  placeholder="Выберите ед. из."
                  options={measureOptions}
                  name="measure"
                  onChange={onChange}
                  helperText={errors.measure || ''}
                  error={!!errors.measure}
                  required={true}
                />
              </>
            )}
            {/*<TextField*/}
            {/*  value={values.price}*/}
            {/*  onChange={onChange}*/}
            {/*  name="price"*/}
            {/*  label="Стоимость"*/}
            {/*  placeholder="Введите стоимость"*/}
            {/*  error={!!errors.price}*/}
            {/*  helperText={errors.price}*/}
            {/*  required={true}*/}
            {/*/>*/}
            <KatoSelectTrigger
              label="Выберите регион"
              value={values.kato ? values.kato.nameRu : ''}
              onChange={onChangeKato}
              helperText={errors.kato}
              error={!!errors.kato}
              required={true}
            />
            <DatePicker
              value={values.deliveryDate}
              name="deliveryDate"
              onChange={onChange}
              label={
                tenderCategory === TenderCategory.WORKER
                  ? 'Дата начала работ'
                  : 'Срок поставки'
              }
              minDate={new Date()}
              placeholderText={'Выберите дату'}
              required={true}
            />
            <DatePicker
              value={values.endDate}
              name="endDate"
              onChange={onChange}
              label={'СРОК ДЕЙСТВИЯ ЗАЯВКИ'}
              placeholderText={'Выберите дату'}
              required={true}
              minDate={new Date()}
              maxDate={
                tenderCategory === TenderCategory.WORKER
                  ? addDays(new Date(), 14)
                  : addDays(new Date(), 29)
              }
            />
          </Grid>
          <TextField
            value={values.description}
            onChange={onChange}
            name="description"
            label="Описание"
            placeholder="Описание документа"
            error={!!errors.description}
            helperText={errors.description}
            required={true}
          />
        </Grid>
      </ExpandableForm>
      {!edit && expanded && (
        <>
          <Paper title="Дополнительные требования" dense={edit}>
            <Grid>
              <TextField
                value={requirement}
                onChange={onChangeRequirement}
                label="требование"
                placeholder="Введите наименование требования. Например, техосмотр не позднее октября"
                error={!!errors.description}
                helperText={errors.description}
                endIcons={[<IconSendMessage onClick={onClickAddRequirement} />]}
                onSendMessage={onClickAddRequirement}
              />
              <RequirementsGrid>
                {requirements.map((requirement, requirementIndex) => (
                  <RequirementStyled key={requirementIndex.toString()}>
                    <p>{requirement}</p>
                    <IconClear
                      onClick={onClickDeleteRequirement(requirementIndex)}
                      className={'form-tender__clearBtn'}
                    />
                  </RequirementStyled>
                ))}
              </RequirementsGrid>
            </Grid>
          </Paper>
          <TenderFormFooterStyled>
            <Button
              text="Продолжить"
              type="submit"
              className="smr-expandable-form__submit"
              onClick={onClickSubmitForm}
            />
          </TenderFormFooterStyled>
        </>
      )}
    </>
  );
}

export default memo(TenderForm);
