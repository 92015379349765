import { Checkbox } from '@app/components';
import { useRef, useState } from 'react';
import { useUserContext } from '@app/providers';
import { updateUser } from '@app/api';
import { UserType } from '@app/models';

export function VisibleOnSearch() {
  const { user, updateUser: updateUserData } = useUserContext();
  const [checked, setChecked] = useState<boolean>(false);
  const timeout = useRef<NodeJS.Timeout>();

  function onChange(value: boolean) {
    updateUserData({
      visible_on_search: value,
    });

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      updateUser(user.id, {
        visible_on_search: value,
      });
    }, 1000);
  }

  if (user.type === UserType.ENTITY) {
    return null;
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Checkbox
        label="Показывать в поиске"
        checked={user.visible_on_search}
        onChange={onChange}
      />
    </div>
  );
}
