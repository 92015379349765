import React, { memo, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, TextField } from '@app/components';
import styled from 'styled-components';
import { emailVerify } from '@app/api';

const StyledVerifyForm = styled.div`
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
`;

function EmailVerification() {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onChangeCode = useCallback((value: string) => {
    if (/^\d*$/.test(value)) {
      setCode(value);
    }
  }, []);

  const handleVerify = async () => {
    setLoading(true);
    setError('');

    const email = searchParams.get('email');

    if (!email) {
      setError('Ошибка: отсутствует email');
      setLoading(false);
      return;
    }

    try {
      const response = await emailVerify({ email, code });
      alert(response.message);
      navigate('/');
    } catch (err: any) {
      setError(err.response?.data?.message || 'Ошибка при подтверждении');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledVerifyForm>
      <h2>Подтверждение почты</h2>
      <p>Введите код, который был отправлен на вашу почту.</p>
      <TextField
        label="Код подтверждения"
        value={code}
        error={!!error}
        helperText={error}
        onChange={onChangeCode}
      />
      <Button
        loading={loading}
        disabled={loading}
        text={'Отправить'}
        type="submit"
        className="smr-expandable-form__submit"
        onClick={handleVerify}
      />
    </StyledVerifyForm>
  );
}

export default memo(EmailVerification);
